@import "~styles/global/_color.scss";
@import "~styles/browser/_breakpoint.scss";

/* reset */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  overflow-wrap: break-word;
}

html,
body {
  color: #727171;
  font-family: "Roboto", "Noto Sans", "Noto Sans TC", "Noto Sans SC", sans-serif;
  font-size: 14px;
  -webkit-text-size-adjust: none;
  background-color: #fff;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  color: #1764be;
  font-size: 100%;
  text-decoration: none;
  vertical-align: baseline;
  background: transparent;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

ol,
ul,
li {
  list-style: none;
}

/* textarea:focus, input:focus{ outline: none; } */
input:focus,
textarea:focus,
select:focus {
  outline-offset: 0px !important;
  outline: none !important;
}
textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

/* change colours to suit your needs */

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  padding: 0;
  margin: 0;
  height: 1px;
  border: 0;
  border-top: solid 1px #e3e3e3;
}

input,
select {
  vertical-align: middle;
}

/**** Clearfix ****/
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + -3px),
    calc(100% - 15px) calc(1em + -3px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: 1px solid #00adef !important;
  }
}

em {
  font-weight: bold;
  color: #3f3f3f;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  &:hover {
    width: 8px;
    height: 8px;
  }
}

::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
  background-color: $gray30;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 4px;
  height: 4px;
  background-color: $gray100;
  &:hover {
    background-color: $gray200;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200) and (max-width: $width-xl) {
  :root {
    transform: scale(0.8);
    transform-origin: top left;
    width: 125%;
    height: 125%;
  }
}
