@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
//TagSearchInput.js
.MuiAutocomplete-popper {
  position: absolute !important;
  top: 0px !important;
  background: transparent !important;
  box-shadow: none !important;
  opacity: 1;
  .MuiAutocomplete-paper {
    height: 100%;
    max-height: 256px;
    width: 240px;
    margin: 0px;
    .MuiAutocomplete-listbox {
      overflow-x: hidden !important;
      li {
        width: 240px;
        height: 40px;
        text-align: left;
        font-size: 13px;
        letter-spacing: 0.12px;
        color: #727171;
        opacity: 1;
        padding-left: 24px;
        &.MuiAutocomplete-option[data-focus="true"] {
          background: #caeafe 0% 0% no-repeat padding-box !important;
        }
        &.MuiAutocomplete-option[aria-selected="true"] {
          background-color: transparent;
        }
      }
    }
  }
}

.MuiPickersDay-daySelected {
  background: #00adef !important;
}

.MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
  border: none;
}

.MuiPickersDay-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.24);
}

.MuiPickersDateRangeDay-rangeIntervalPreview {
  display: flex;
}

.MuiPickersDesktopDateRangeCalendar-calendar {
  height: auto;
  min-height: 258px;
}

.MuiMenu-list {
  padding: 0 !important;
  li {
    color: #595757;
    font-size: 13px;
  }
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #00adef !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #00adef !important;
}
.MuiInput-underline.Mui-error:before {
  border-bottom: 1px solid #f44336 !important;
}
.MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #f44336 !important;
}
.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}
.MuiInput-underline.Mui-error:hover:before {
  border-bottom: 2px solid #f44336 !important;
}

li.MuiListItem-root {
  display: block;
}
