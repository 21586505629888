@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/components/_statesTags.module.scss";
@import "~styles/components/_imageWithTimeIcon.module.scss";

.userHeaderIcon {
  .circleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    > div {
      font-size: 16px;
      color: #727171;
    }
    &:hover {
      background-color: #e8e8e8;
    }
    &.lightBlueBg {
      background-color: #caeafe;
    }
  }
}

.authModalContentWrapper {
  width: 100%;
  padding: 20px 0;
  .contentHeader {
    padding: 16px 0;
    font-size: 24px;
    color: #00adef;
    text-decoration: none solid rgb(0, 173, 239);
    line-height: 32px;
    text-align: center;
  }
  .detail {
    font-size: 20px;
    color: #727171;
    text-decoration: none solid rgb(114, 113, 113);
    line-height: 24px;
    text-align: center;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 14px 0 10px 0;
      .avatarWrapper {
        margin: auto;
      }
    }
    .email {
      font-size: 16px;
      color: #727171;
      line-height: 24px;
      text-align: center;
      margin-bottom: 17px;
    }
  }
  .forgetPassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      color: $blue500;
      font-size: 0.8125rem;
      cursor: pointer;
    }
  }
}
.eyes {
  height: 20px;
}
.adjustAuthDialog {
  .MuiDialogPaper {
    margin-top: 64px !important;
  }
}

//customer material icon
@mixin icon($width: 36px, $height: 36px, $color: #fff, $circle: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $width;
  height: $height;
  color: $color;
  font-size: 24px;
  @content;
  @if $circle {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

//Card item accessories
.imgBox {
  position: relative;
  width: 100%;
  height: 136px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.videoTime {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: $f12;
  color: #fff;
  border-radius: 2px;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.5);
}

.iconType {
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.avatarBox {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: $f12;
  color: $gray500;
  .nameBox {
    margin-left: 10px;
    font-size: $f12;
    color: $gray500;
    font-weight: normal;
  }
}

//Custom Progress Bar

@mixin cusProgressBarTemplate($width: 100%, $maxWidth: 100%) {
  width: $width;
  max-width: $maxWidth;
  height: 8px !important;
  border-radius: 4px;
  color: $blue500 !important;
  background-color: $gray50 !important;
}

.cusProgressBar {
  @include cusProgressBarTemplate(100%, 480px);
}

// custom Tab components
@mixin tabListTemplate($alignItem: center, $justifyContent: center) {
  width: 100%;
  display: flex;
  align-items: $alignItem;
  justify-content: $justifyContent;
}
@mixin tabTemplate(
  $width: 120px,
  $height: 48px,
  $margin: 0 0 0 0,
  $padding: 0 24px
) {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: $width;
  height: $height;
  font-size: $f16;
  font-weight: $fw500;
  text-align: center;
  color: $gray500;
  margin: $margin;
  cursor: pointer;
  padding: $padding;
  @content;
  &:last-child {
    margin-right: 0;
  }
}
.tabLayout {
  width: 100%;
  .tabList {
    @include tabListTemplate();
    .tab {
      @include tabTemplate();
      &.tabSelected {
        position: relative;
        color: $blue500;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          border-bottom: solid 3px $blue500;
        }
      }
      &.tabHidden {
        display: none;
      }
    }
  }
  .cusTabList {
    width: 100%;
    border-bottom: 1px solid $gray02;
  }
  &.myContentTab {
    .tabList {
      @include tabListTemplate(flex-start, flex-start);
      .tab {
        @include tabTemplate(120px, 48px, 0);
      }
    }
  }
}

//order & history video  course bundles component
.subContent {
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 48px;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 0 16px 174px;
  border-top: 1px solid $gray02;
  &:last-child {
    margin-bottom: 0;
  }
  .imgBox {
    @include videoSize(112px, false);
  }
  .subContentText {
    width: 100%;
    max-width: 480px;
    .subTitle {
      font-size: $f12;
      color: $gray100;
      margin-bottom: 8px;
    }
    .countText {
      font-size: $f12;
      color: $gray100;
      margin-bottom: 8px;
    }
    .title {
      font-size: $f16;
      line-height: $lh24;
      color: $gray700;
    }
  }
  &.orderBilling {
    padding: 16px 0 16px 198px;
  }
}

//custom menu
.cusMenuItem {
  display: flex !important;
  align-items: center !important;
  padding: 10px 30px !important;
  color: $gray500 !important;
  font-size: 13px !important;
  &:hover {
    background-color: #caeafe !important;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray700;
    margin-right: 10px;
    font-size: $f20;
  }
}

.pointer {
  cursor: pointer;
}

.defaultCursor {
  color: #727171;
}

.mb_5 {
  margin-bottom: 5px !important;
}

.custom_truncate {
  display: inline-block;
  position: relative;
  line-height: 14px;
}

.studio_info {
  padding-left: 360px;
  .studio_info_wrapper {
    margin-top: 46px;
    max-width: 812px;
    width: calc(100vw - 560px);
    padding-bottom: 10px;
  }
  .title_main {
    height: 24px;
    font-size: 20px;
    color: #727171;
    line-height: 24px;
    display: inline-block;
    + span {
      display: inline-block;
      font-size: 14px;
      padding-left: 20px;
      list-style: circle;
      span {
        width: 5px;
        height: 5px;
        background: #000;
        display: inline-block;
        margin: 0 10px;
        border-radius: 50%;
      }
    }
  }
}

.wrapper {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  display: block;
  position: relative;
  min-width: 400px;
  width: 100%;
}
