@import "./_variable";
@import "./_color";
@import "~styles/browser/_breakpoint";

// global style setting

main {
  min-height: calc(100vh - 72px);
  padding: 72px 88px;
}

// global  components&element styles format
.progress-bar {
  transform: translateY(4px);
  width: 100vw;
  background-color: #e8e8e8 !important;
  color: #00adef !important;
}

textarea {
  width: 100%;
  height: 100%;
  font-size: $f16;
  color: $gray500;
  line-height: $lh20;
  border-radius: 6px;
  padding: 12px 10px;
  background-color: $gray15;
  border-color: transparent;
  caret-color: $blue500;
  resize: none;
  &:focus {
    border-color: $blue500;
  }
  &::placeholder {
    opacity: 0.5;
  }
}

input::-ms-reveal {
  display: none;
}
